import { RefObject } from "react";
import { EInsertDetailItemType } from "../../../../interfaces/IInsertDetail";

export interface ICoverItemRef {
  indexDetail: number;
  coverIndex: number;
  type: EInsertDetailItemType;
  coverRef: RefObject<any>;
  itemRef: RefObject<any>;
}

export let coverItemsRefs: ICoverItemRef[] = [];

const CoverItemsRefsFunctions = {
  exists: (type: EInsertDetailItemType, indexDetail: number, coverIndex: number) => {
    const item = coverItemsRefs.find(
      (item) => item.type == type && item.indexDetail == indexDetail && item.coverIndex == coverIndex
    );

    return {
      exists: item != undefined,
      item: item,
    };
  },
  addOrUpdateItemRef: async (item: ICoverItemRef) => {
    if (CoverItemsRefsFunctions.exists(item.type, item.indexDetail, item.coverIndex).exists) {
      const newCoverItemsRefs = coverItemsRefs.map((i) => {
        if (i.type == item.type && i.indexDetail == item.indexDetail && i.coverIndex == item.coverIndex) {
          return item;
        }

        return i;
      });
      coverItemsRefs = [...newCoverItemsRefs];
      return;
    }

    coverItemsRefs = [...coverItemsRefs, item];
  },
  removeItemRef: (type: EInsertDetailItemType, indexDetail: number) => {
    coverItemsRefs = coverItemsRefs.filter((item) => item.type != type && indexDetail != indexDetail);
    return coverItemsRefs;
  },
  removeAll: () => {
    coverItemsRefs = [];
    return coverItemsRefs;
  },
  removeAllRefsByCover: (coverIndex: number) => {
    coverItemsRefs = coverItemsRefs.filter((item) => item.coverIndex != coverIndex);
    return coverItemsRefs;
  },
  updateCoverIndexRefs: (coverIndexDeleted: number) => {
    coverItemsRefs = coverItemsRefs
      .filter((item) => item.coverIndex != coverIndexDeleted)
      .map((item) => {
        if (item.coverIndex > coverIndexDeleted) {
          item.coverIndex--;
        }

        return item;
      });
  },
};

export default CoverItemsRefsFunctions;
