import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import ChainedBackend from "i18next-chained-backend";
import HttpBackend from "i18next-http-backend";
import resourcesToBackend from "i18next-resources-to-backend";
import { ENGLISH } from "./constants/Languages";

// @ts-ignore
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(ChainedBackend)
  .init({
    detection: {
      order: ["localStorage"],
      lookupLocalStorage: "lang",
      caches: ["localStorage"],
    },
    backend: {
      backends: [resourcesToBackend((lng, ns) => import(`./locales/${ns}.json`)), HttpBackend],
      backendOptions: [
        {},
        {
          loadPath: `${process.env.REACT_APP_API_URL}/languages/languagetranslationbycode/{{lng}}`,
        },
      ],
    },
    debug: false,
    fallbackLng: ENGLISH,
    load: "currentOnly",
    interpolation: {
      escapeValue: false,
    },
    defaultNS: "translations",
  });

export default i18n;
