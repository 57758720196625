interface IFrameAndCoverStyle {
  frame: IFrameStyle;
  cover: ICoverStyle;
  tile: ITileStyle;
}
interface IFrameStyle {
  padding: string;
}
interface ICoverItemStyle {
  marginTop: string;
  marginRight: string;
  marginBottom: string;
  marginLeft: string;
}

interface ITileStyle {
  className: string;
}

interface ICoverStyle {
  size: number;
  padding: string;
  width: string;
  height: string;
  backgroundSize: string;
  coverItems: ICoverItemStyle[];
}

const frameType63: IFrameAndCoverStyle = {
  frame: {
    padding: "0px",
  },
  cover: {
    size: 63,
    padding: "12px",
    backgroundSize: "238.11025px",
    width: "238.11025px",
    height: "238.11025px",
    coverItems: [
      {
        marginTop: "80px",
        marginRight: "0px",
        marginBottom: "80px",
        marginLeft: "80px",
      },
      {
        marginTop: "80px",
        marginRight: "0px",
        marginBottom: "80px",
        marginLeft: "26px",
      },
      {
        marginTop: "80px",
        marginRight: "0px",
        marginBottom: "80px",
        marginLeft: "26px",
      },
      {
        marginTop: "80px",
        marginRight: "0px",
        marginBottom: "80px",
        marginLeft: "26px",
      },
      {
        marginTop: "80px",
        marginRight: "80px",
        marginBottom: "80px",
        marginLeft: "26px",
      },
    ],
  },
  tile: {
    className: "tile-62",
  },
};

const frameType55: IFrameAndCoverStyle = {
  frame: {
    padding: "0px",
  },
  cover: {
    size: 55,
    padding: "12px",
    backgroundSize: "209px",
    width: "207.87401px",
    height: "210px",
    coverItems: [
      {
        marginTop: "94.5px",
        marginRight: "0px",
        marginBottom: "0px",
        marginLeft: "95.3px",
      },
      {
        marginTop: "94.5px",
        marginRight: "0px",
        marginBottom: "0px",
        marginLeft: "56.3px",
      },
      {
        marginTop: "94.5px",
        marginRight: "0px",
        marginBottom: "0px",
        marginLeft: "56.3px",
      },
      {
        marginTop: "94.5px",
        marginRight: "0px",
        marginBottom: "0px",
        marginLeft: "56.3px",
      },
      {
        marginTop: "94.5px",
        marginRight: "0px",
        marginBottom: "0px",
        marginLeft: "56.3px",
      },
    ],
  },
  tile: {
    className: "tile-55",
  },
};

const frameType54: IFrameAndCoverStyle = {
  frame: {
    padding: "0px",
  },
  cover: {
    size: 54,
    padding: "12px",
    backgroundSize: "204.09448819px",
    width: "204.09448819px",
    height: "204.09448819px",
    coverItems: [
      {
        marginTop: "97px",
        marginRight: "0px",
        marginBottom: "0px",
        marginLeft: "97px",
      },
      {
        marginTop: "97px",
        marginRight: "0px",
        marginBottom: "0px",
        marginLeft: "60px",
      },
      {
        marginTop: "97px",
        marginRight: "0px",
        marginBottom: "0px",
        marginLeft: "60px",
      },
      {
        marginTop: "97px",
        marginRight: "0px",
        marginBottom: "0px",
        marginLeft: "60px",
      },
      {
        marginTop: "97px",
        marginRight: "0px",
        marginBottom: "0px",
        marginLeft: "60px",
      },
    ],
  },
  tile: {
    className: "tile-54",
  },
};

const frameType59: IFrameAndCoverStyle = {
  frame: {
    padding: "0px",
  },
  cover: {
    size: 59,
    padding: "27.779527559px",
    backgroundSize: "225.63779528px", //264.56px (70mm) // 262.67716px (69.5mm)
    width: "225.63779528px",
    height: "225.63779528px",
    coverItems: [
      {
        marginTop: "86.5px",
        marginRight: "0px",
        marginBottom: "86.5px",
        marginLeft: "85px",
      },
      {
        marginTop: "86.5px",
        marginRight: "0px",
        marginBottom: "86.5px",
        marginLeft: "-1px",
      },
      {
        marginTop: "86.5px",
        marginRight: "0px",
        marginBottom: "86.5px",
        marginLeft: "-1px",
      },
      {
        marginTop: "86.5px",
        marginRight: "0px",
        marginBottom: "86.5px",
        marginLeft: "-1px",
      },
    ],
  },
  tile: {
    className: "tile-59",
  },
};

const frameType70: IFrameAndCoverStyle = {
  frame: {
    padding: "0px",
  },
  cover: {
    size: 70,
    padding: "38px",
    backgroundSize: "262.67716535px", //264.56px (70mm) // 262.67716px (69.5mm)
    width: "262.67716535px",
    height: "262.67716535px",
    coverItems: [
      {
        marginTop: "80px",
        marginRight: "0px",
        marginBottom: "80px",
        marginLeft: "102px",
      },
      {
        marginTop: "80px",
        marginRight: "0px",
        marginBottom: "80px",
        marginLeft: "5.66929px",
      },
      {
        marginTop: "80px",
        marginRight: "0px",
        marginBottom: "80px",
        marginLeft: "5.66929px",
      },
      {
        marginTop: "80px",
        marginRight: "0px",
        marginBottom: "80px",
        marginLeft: "5.66929px",
      },
      {
        marginTop: "80px",
        marginRight: "80px",
        marginBottom: "80px",
        marginLeft: "5.66929px",
      },
    ],
  },
  tile: {
    className: "tile-70",
  },
};

export const FrameStyleProps = new Map<string, IFrameAndCoverStyle>([
  ["carat", frameType63],
  ["future", frameType63],
  ["sidus", frameType59],
  ["calle", frameType55],
  ["albatpr", frameType70],
  ["tpr", frameType70],
  ["purestainlesssteel", frameType63],
  ["skyniessen", frameType54],
  ["buschbalancesi", frameType55],
  ["axcentpur", frameType63],
  ["futurelinear", frameType63],
  ["solo", frameType63],
  ["axcent", frameType63],
  ["dynasty", frameType63],
]);
