import { IElementSize } from "../../../../interfaces/DTO/IElementSize";
import {
  EInsertDetailItemType,
  IInsertDetail,
  IInsertDetailItem,
  InsertDetailItemDimension,
  InsertDetailItemFont,
  InsertDetailItemPosition,
} from "../../../../interfaces/IInsertDetail";
import { getElementPosition, getElementSize } from "./utils";
import CoverItemsRefsFunctions from "./utils-cover-items-refs";
import { getItemsDimensions } from "./utils-get-dimensions";
import { IDesignDimensions } from "../../../../interfaces/IDesignDimensions";
import { HUNDRED_PERCENT, TEN_PERCENT } from "../../../../constants/NumberValues";

interface IAddItemBase {
  id?: string;
  indexDetail: number;
  color: string;
  coverIndex: number;
  insertDetail: IInsertDetail;
  setInsertDetail: (detail: IInsertDetail) => void;
  designSizes: IDesignDimensions;
}

interface IAddIcon extends IAddItemBase {
  iconId: string;
  iconName: string;
  materialNumber: string;
  designSizes: IDesignDimensions;
  iconSizePercentage: number;
}

interface IAddText extends IAddItemBase {
  textValue: string;
  size: number;
  name: string;
  style: string;
  weight: string;
  lineSpacing: number;
  designSizes: IDesignDimensions;
}

interface IAddLineBase extends IAddItemBase {
  lineColor?: string;
  lineGaps?: number;
}

interface IAddSeparationLine extends IAddLineBase {}

interface IAddSliderLine extends IAddLineBase {}

let timeoutItemsId: ReturnType<typeof setTimeout>;

export const debounce = (fn: Function, ms: number) => {
  clearTimeout(timeoutItemsId);
  timeoutItemsId = setTimeout(() => fn(), ms);
};

export let items: IInsertDetailItem[] = [];
// let itemsQueue: IInsertDetailItem[] = [];

const CoverItemsFunctions = {
  exists: (type: EInsertDetailItemType, indexDetail: number, insertDetailId: string) => {
    const item = items.find(
      (item) => item.type === type && item.indexDetail === indexDetail && item.insertDetailId === insertDetailId
    );
    return {
      exists: item != undefined,
      item,
    };
  },

  getItemInfo: async (
    type: EInsertDetailItemType,
    indexDetail: number,
    insertDetail: any,
    coverIndex: number,
    designSizes: IDesignDimensions,
    iconSizePercentage: number = HUNDRED_PERCENT,
    fontSize: number = TEN_PERCENT
  ) => {
    const insertDetailId = insertDetail.id;
    const layoutName = insertDetail?.insertType?.name;
    const item = CoverItemsFunctions.exists(type, indexDetail, insertDetailId);
    const ref = CoverItemsRefsFunctions.exists(type, indexDetail, coverIndex).item;
    let itemRef = ref?.itemRef;
    let elementSizeProps: IElementSize | undefined = undefined;

    if (type == EInsertDetailItemType.Text) {
      elementSizeProps = {
        width: (itemRef! as any).current.handleGetCanvasDimension().width,
        height: (itemRef! as any).current.handleGetCanvasDimension().height,
      };
      itemRef = (itemRef! as any).current.handleGetCanvasDimension().ref;
    }

    const initialDimension = getElementPosition(ref?.coverRef!, itemRef!, getElementSize(itemRef!), elementSizeProps);
    const actualDimensions = getItemsDimensions(
      indexDetail,
      layoutName,
      designSizes,
      insertDetail,
      type,
      iconSizePercentage,
      fontSize
    );

    const dimension = {
      ...initialDimension,
      heightmm: actualDimensions?.actualHeightMm || initialDimension?.heightmm,
      widthmm: actualDimensions?.actualWidthMm || initialDimension?.widthmm,
      xmm: actualDimensions?.actualPosXMm || initialDimension?.xmm,
      ymm: actualDimensions?.actualPosYMm || initialDimension?.ymm,
      startPositionXMm: actualDimensions?.actualStartXMm || initialDimension?.startPositionXMm,
      startPositionYMm: actualDimensions?.actualStartYMm || initialDimension?.startPositionYMm,
      endPositionXMm: actualDimensions?.actualEndXMm || initialDimension?.endPositionXMm,
      endPositionYMm: actualDimensions?.actualEndYMm || initialDimension?.endPositionYMm,
    };

    return {
      item,
      ref,
      dimension,
    };
  },

  removeAll: () => {
    items = [];
  },

  removeAllByInsertDetailId: (insertDetailId: string) => {
    items = items.filter((item) => item.insertDetailId != insertDetailId);
  },

  initialItems: (insertDetail: IInsertDetail, setInsertDetail) => {
    const items = insertDetail.items!;

    items.forEach((item) => {
      switch (item.type) {
        case EInsertDetailItemType.Icon:
        case EInsertDetailItemType.Text:
        case EInsertDetailItemType.SeparationLine:
        case EInsertDetailItemType.Slider:
          addInsertDetailItem(item, insertDetail, setInsertDetail);
          break;

        default:
          throw Error(`Argument Exception: ${item.type}`);
      }
    });
  },

  addOrUpdateIcon: async (props: IAddIcon) => {
    const info = await CoverItemsFunctions.getItemInfo(
      EInsertDetailItemType.Icon,
      props.indexDetail,
      props.insertDetail,
      props.coverIndex,
      props.designSizes,
      props.iconSizePercentage
    );

    //force text position
    var iconExists = props.insertDetail.items.find(
      (item) => item.type == EInsertDetailItemType.Icon && item.indexDetail == props.indexDetail
    );
    let textPosition = new InsertDetailItemPosition(info.dimension?.xmm!, info.dimension?.ymm!);
    var text = props.insertDetail.items.find(
      (item) => item.type == EInsertDetailItemType.Text && item.indexDetail == props.indexDetail
    );
    if (!iconExists && text) {
      const usePosition = await CoverItemsFunctions.getItemInfo(
        EInsertDetailItemType.Text,
        props.indexDetail,
        props.insertDetail,
        props.coverIndex,
        props.designSizes
      );
      textPosition = new InsertDetailItemPosition(usePosition.dimension?.xmm!, usePosition.dimension?.ymm!);
      const updatedText: IInsertDetailItem = {
        type: EInsertDetailItemType.Text,
        indexDetail: text.indexDetail,
        insertDetailId: text.insertDetailId,
        color: text.color,
        textValue: text.textValue,
        lineSpacing: text.lineSpacing,
        font: text.font,
        dimension: text.dimension,
        position: textPosition,
        coverIndex: text.coverIndex,
      };

      const newitems = items.map((item) => {
        if (
          item.type == EInsertDetailItemType.Text &&
          item.indexDetail == updatedText.indexDetail &&
          item.insertDetailId == updatedText.insertDetailId
        ) {
          updatedText.id = props.id;
          return updatedText;
        }

        return item;
      });

      items = [...newitems];
    }

    const newItem: IInsertDetailItem = {
      type: EInsertDetailItemType.Icon,
      indexDetail: props.indexDetail,
      insertDetailId: props.insertDetail.id,
      color: props.color,
      iconId: props.iconId,
      iconName: props.iconName,
      materialNumber: props.materialNumber,
      dimension: new InsertDetailItemDimension(info.dimension?.widthmm!, info.dimension?.heightmm!),
      position: new InsertDetailItemPosition(info.dimension?.xmm!, info.dimension?.ymm!),
      coverIndex: props.coverIndex,
    };

    if (info.item.exists) {
      const newitems = items.map((item) => {
        if (
          item.type === EInsertDetailItemType.Icon &&
          Number(item.indexDetail) === Number(props.indexDetail) &&
          item.insertDetailId === props.insertDetail.id
        ) {
          newItem.id = props.id;
          return newItem;
        }

        return item;
      });

      items = [...newitems];
      CoverItemsFunctions.distinctItems(props.insertDetail, props.setInsertDetail);
      return;
    }

    items = [...items, newItem];
    CoverItemsFunctions.distinctItems(props.insertDetail, props.setInsertDetail);
  },

  addOrUpdateText: async (props: IAddText) => {
    const info = await CoverItemsFunctions.getItemInfo(
      EInsertDetailItemType.Text,
      props.indexDetail,
      props.insertDetail,
      props.coverIndex,
      props.designSizes,
      HUNDRED_PERCENT,
      props.size
    );

    let position = new InsertDetailItemPosition(info.dimension?.xmm!, info.dimension?.ymm!);
    //force text position
    var icon = props.insertDetail.items.find(
      (item) => item.type == EInsertDetailItemType.Icon && item.indexDetail == props.indexDetail
    );
    if (!icon) {
      const usePosition = await CoverItemsFunctions.getItemInfo(
        EInsertDetailItemType.Icon,
        props.indexDetail,
        props.insertDetail,
        props.coverIndex,
        props.designSizes
      );
      position = new InsertDetailItemPosition(usePosition.dimension?.xmm!, usePosition.dimension?.ymm!);
    }
    //force text position

    const newItem: IInsertDetailItem = {
      type: EInsertDetailItemType.Text,
      indexDetail: props.indexDetail,
      insertDetailId: props.insertDetail.id,
      color: props.color,
      textValue: props.textValue,
      lineSpacing: props.lineSpacing,
      font: new InsertDetailItemFont(props.size, props.name, props.style, props.weight),
      dimension: new InsertDetailItemDimension(info.dimension?.widthmm!, info.dimension?.heightmm!),
      position: position,
      coverIndex: props.coverIndex,
    };

    if (info.item.exists) {
      const newitems = items.map((item) => {
        if (
          item.type == EInsertDetailItemType.Text &&
          item.indexDetail == props.indexDetail &&
          item.insertDetailId == props.insertDetail.id
        ) {
          newItem.id = props.id;
          return newItem;
        }

        return item;
      });

      items = [...newitems];
      CoverItemsFunctions.distinctItems(props.insertDetail, props.setInsertDetail);
      return;
    }

    items = [...items, newItem];
    CoverItemsFunctions.distinctItems(props.insertDetail, props.setInsertDetail);
  },

  addOrUpdateSeparationLine: async (props: IAddSeparationLine) => {
    const info = await CoverItemsFunctions.getItemInfo(
      EInsertDetailItemType.SeparationLine,
      props.indexDetail,
      props.insertDetail,
      props.coverIndex,
      props.designSizes
    );

    const newItem: IInsertDetailItem = {
      type: EInsertDetailItemType.SeparationLine,
      indexDetail: props.indexDetail,
      insertDetailId: props.insertDetail.id,
      color: props.color,
      dimension: new InsertDetailItemDimension(info.dimension?.widthmm!, info.dimension?.heightmm!),
      position: new InsertDetailItemPosition(info.dimension?.xmm!, info.dimension?.ymm!),
      lineColor: props.color,
      lineSpacing: 1,
      lineGaps: 0,
      positionStart: new InsertDetailItemPosition(info.dimension?.startPositionXMm!, info.dimension?.startPositionYMm!),
      positionEnd: new InsertDetailItemPosition(info.dimension?.endPositionXMm!, info.dimension?.endPositionYMm!),
      coverIndex: props.coverIndex,
      gridLine: true,
    };

    if (info.item.exists) {
      const newitems = items.map((item) => {
        if (
          item.type == EInsertDetailItemType.SeparationLine &&
          item.indexDetail == props.indexDetail &&
          item.insertDetailId == props.insertDetail.id
        ) {
          newItem.id = props.id;
          return newItem;
        }

        return item;
      });

      items = [...newitems];

      debounce(() => {
        CoverItemsFunctions.distinctItems(props.insertDetail, props.setInsertDetail);
      }, 1000);
      return;
    }
    items = [...items, newItem];
    debounce(() => {
      CoverItemsFunctions.distinctItems(props.insertDetail, props.setInsertDetail);
    }, 1000);
  },

  addOrUpdateSliderLine: async (props: IAddSliderLine) => {
    const info = await CoverItemsFunctions.getItemInfo(
      EInsertDetailItemType.Slider,
      props.indexDetail,
      props.insertDetail,
      props.coverIndex,
      props.designSizes
    );

    const newItem: IInsertDetailItem = {
      type: EInsertDetailItemType.Slider,
      indexDetail: props.indexDetail,
      insertDetailId: props.insertDetail.id!,
      color: props.color,
      dimension: new InsertDetailItemDimension(info.dimension?.widthmm!, info.dimension?.heightmm!),
      position: new InsertDetailItemPosition(info.dimension?.xmm!, info.dimension?.ymm!),
      lineColor: props.color,
      lineGaps: 0,
      positionStart: new InsertDetailItemPosition(info.dimension?.startPositionXMm!, info.dimension?.startPositionYMm!),
      positionEnd: new InsertDetailItemPosition(info.dimension?.endPositionXMm!, info.dimension?.endPositionYMm!),
      coverIndex: props.coverIndex,
    };

    if (info.item.exists) {
      const newitems = items.map((item) => {
        if (
          item.type == EInsertDetailItemType.Slider &&
          item.indexDetail == props.indexDetail &&
          item.insertDetailId == props.insertDetail.id
        ) {
          newItem.id = props.id;
          return newItem;
        }

        return item;
      });

      items = [...newitems];

      debounce(() => {
        CoverItemsFunctions.distinctItems(props.insertDetail, props.setInsertDetail);
      }, 1000);

      return;
    }

    items = [...items, newItem];
    debounce(() => {
      CoverItemsFunctions.distinctItems(props.insertDetail, props.setInsertDetail);
    }, 1000);
  },

  distinctItems: (insertDetail?: IInsertDetail, setInsertDetail?: (detail: IInsertDetail) => void) => {
    items = items.filter(
      (insertDetailItem, index, insertDetailItems) =>
        insertDetailItems.findIndex(
          (insertDetailItem2) =>
            insertDetailItem2.type === insertDetailItem.type &&
            Number(insertDetailItem2.indexDetail) === Number(insertDetailItem.indexDetail) &&
            insertDetailItem2.insertDetailId === insertDetailItem.insertDetailId
        ) === index
    );

    setInsertDetail!({ ...insertDetail, items: items.filter((x) => x.insertDetailId == insertDetail?.id) });
  },

  RemoveByInsertDetailId: (insertDetailId: string) => {
    items = items.filter((item) => item.insertDetailId != insertDetailId);
  },
  RemoveByInsertDetailIdAndTypes: (insertDetailId: string, indexDetail: number, itemTypes: EInsertDetailItemType[]) => {
    items = items.filter(
      (item) =>
        (item.insertDetailId == insertDetailId && item.indexDetail != indexDetail) ||
        (item.insertDetailId == insertDetailId && item.indexDetail == indexDetail && !itemTypes.includes(item.type)) ||
        item.insertDetailId != insertDetailId
    );
  },
};

function addInsertDetailItem(item: IInsertDetailItem, insertDetail: IInsertDetail, setInsertDetail) {
  if (
    item.insertDetailId !== insertDetail.id ||
    item.insertDetailId === undefined ||
    items.some(
      (existingItem) =>
        existingItem.insertDetailId === item.insertDetailId &&
        Number(existingItem.indexDetail) === Number(item.indexDetail) &&
        (existingItem.iconId === item.iconId || existingItem.textValue === item.textValue)
    )
  ) {
    return;
  }

  items = [...items, item];
  CoverItemsFunctions.distinctItems(insertDetail, setInsertDetail);
}

export default CoverItemsFunctions;
