import { Button, Typography, Box, Backdrop, CircularProgress } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import ProductCarouselSwiper from "../../components/Carousel/ProductCarouselSwiper";
import ProductGrid from "./Components/ProductGrid/ProductGrid";

import { useTranslation } from "react-i18next";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import { ReactComponent as CarouselSvg } from "./Icons/carousel.svg";
import { ReactComponent as GridSvg } from "./Icons/grid.svg";
import ArrowBackIosSharpIcon from "@mui/icons-material/ArrowBackIosSharp";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { useState } from "react";
import { useStoreActions, useStoreState } from "../../hooks";
import ConfiguratorWelcomeDialog, {
  configuratorWelcomeDialogRef,
} from "./Components/WelcomeModal/ConfiguratorDialog/ConfiguratorWelcomeDialog";
import NextButton from "./NextButton";
import ProductDesignService from "../../services/ProductDesignService";
import { IInsertDetail } from "../../interfaces/IInsertDetail";
import { BUTTON } from "../../constants/InsertType";
import { NO_ROTATION_0_DEGREE } from "../../constants/NumberValues";
import { IDesign } from "../../interfaces/IDesign";
import { DesignName } from "../../../src/components/DesigneName/DesignName";

const ConfiguratorStartContent: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { currentUser } = useStoreState((state) => state.auth);

  const { thunkOpenSnackBar } = useStoreActions((actions) => actions.app);
  const { designs, insertTypes, productDesigns } = useStoreState((state) => state.designs.data);
  const { userDesignData, currentDesignId, currentProjectId } = useStoreState((state) => state.designs.configuration);
  const {
    thunkAutoSave,
    setUserDesignData,
    thunkGetUserDesignDataFromProductDesignResponse,
    setUserDesignDataFromProductDesignResponse,
    setCurrentDesignId,
    thunkGetDesignSizes,
  } = useStoreActions((actions) => actions.designs);

  const [isGrid, setIsGrid] = useState(false);
  const [selectedDesignName, setSelectedDesignName] = useState<IDesign>(designs[0]);
  const [loading, setLoading] = useState(false);
  const [swiperInstance, setSwiperInstance] = useState<any>(null);

  const handleChangeView = () => {
    setIsGrid(!isGrid);
  };

  const setDesignName = (design: IDesign) => {
    setSelectedDesignName(design);
  };

  const handleGoToFrameConfiguration = async () => {
    setLoading(true);

    if (
      ![
        "carat",
        "solo",
        "buschbalancesi",
        "future",
        "futurelinear",
        "axcent",
        "dynasty",
        "axcentpur",
        "purestainlesssteel",
        "albatpr",
        "tpr",
        "skyniessen",
        "sidus",
        "calle",
      ].includes(userDesignData?.design?.code!)
    ) {
      setLoading(false);

      thunkOpenSnackBar({
        message: t("configurator_start.message.design_cannot_selected"),
        severity: "info",
      });
      return;
    }

    let newInsertDetails: IInsertDetail[] = [];
    let localUserDesignData = userDesignData;

    if (!userDesignData?.userCustomDesign?.insertDetails) {
      const response = await ProductDesignService.addNewProductDesign({
        projectId: currentProjectId!,
        company: userDesignData?.design?.company!,
        countryId: currentUser?.countryId!,
        design: selectedDesignName.name,
      });

      localUserDesignData = await thunkGetUserDesignDataFromProductDesignResponse(response.data);
      setUserDesignDataFromProductDesignResponse(response.data);
      setCurrentDesignId(currentDesignId);
      response.data.design !== undefined && thunkGetDesignSizes(response.data.design.id as string);

      newInsertDetails = updateInsertDetails(response.data.insertDetails);
    } else {
      localUserDesignData = userDesignData;
      thunkGetDesignSizes(userDesignData.userCustomDesign.designId as string);
      newInsertDetails = updateInsertDetails(userDesignData?.userCustomDesign.insertDetails);
    }

    const newValues = {
      ...localUserDesignData,
      ...{
        userCustomDesign: {
          ...localUserDesignData?.userCustomDesign!,
          ...{
            pathname: "/frame-configuration",
            insertDetails: newInsertDetails!,
            room: "",
            location: "",
          },
        },
      },
    };

    setUserDesignData(newValues);

    await thunkAutoSave(newValues);

    setLoading(false);

    navigate("/frame-configuration", {
      state: {
        isDesignSelected: productDesigns.find((p) => p.id === currentDesignId)?.design !== undefined,
      },
    });
  };

  const updateInsertDetails = (insertDetails: IInsertDetail[]) => {
    return insertDetails.map((detail, index) => {
      detail.insertColor = userDesignData?.design?.insertColor;
      detail.insertType =
        userDesignData?.userCustomDesign?.insertDetails[index].insertType ?? insertTypes.find((x) => x.name === BUTTON);
      detail.rotate = userDesignData?.userCustomDesign?.insertDetails?.[index].rotate ?? NO_ROTATION_0_DEGREE;
      detail.hasGridLine = true;
      return detail;
    });
  };

  return (
    <>
      <div className="productLines">
        <div className="productLines__topLinks">
          <Link
            className="productLines__backToDashboardLink"
            to="/dashboard"
          >
            <Button variant="text">
              <ChevronLeftOutlinedIcon fontSize="large" />
              <Typography>{t("configuratorGoBackToDashboard")}</Typography>
            </Button>
          </Link>
          <Button
            onClick={handleChangeView}
            variant="text"
            className="productLines__SwitchViewButton"
            sx={{ textTransform: "none" }}
          >
            {isGrid ? (
              <>
                <CarouselSvg style={{ marginRight: "20px" }} />
                <Typography
                  variant="caption"
                  fontSize="16px"
                  fontWeight={400}
                >
                  {t("configurator_start.label.switch_to_carousel_view")}
                </Typography>
              </>
            ) : (
              <>
                <GridSvg style={{ marginRight: "20px" }} />
                <Typography
                  variant="caption"
                  fontSize="16px"
                  fontWeight={400}
                >
                  {t("configurator_start.label.switch_to_grid_view")}
                </Typography>
              </>
            )}
          </Button>
        </div>
        <div className={"productLines__content " + (isGrid && "productLines__content--grid")}>
          <div className="content__header">
            <Typography
              variant="h1"
              fontSize="32px"
              fontWeight={500}
            >
              {t("configurator_start.label.select_design_range")}
            </Typography>
          </div>
          <div className="productLines__list">
            {(isGrid && (
              <ProductGrid
                designs={designs}
                setDesignName={setDesignName}
              />
            )) || (
              <div className="carouselView">
                <ArrowBackIosSharpIcon
                  onClick={() => swiperInstance.slidePrev()}
                  className="slide__leftArrow"
                />
                <ProductCarouselSwiper
                  setDesignName={setDesignName}
                  designs={designs.sort((a: any, b: any) => {
                    return a.name.localeCompare(b.name);
                  })}
                  setSwiperInstance={setSwiperInstance}
                />
                <ArrowForwardIosSharpIcon
                  onClick={() => swiperInstance.slideNext()}
                  className="slide__rightArrow"
                />
              </div>
            )}
          </div>
          {!isGrid && (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              style={{
                marginBottom: "35px",
                zIndex: 1,
              }}
            >
              <Typography
                variant="body1"
                fontSize="16px"
                fontWeight={400}
                color="#1D1D1B"
              >
                {t("configurator_start.label.selected_design")}
              </Typography>
              <Typography
                variant="body1"
                fontSize="24px"
                fontWeight={500}
                color="#1D1D1B"
              >
                <DesignName design={selectedDesignName} />
              </Typography>
            </Box>
          )}
          <div className={"productLines__selectBtn " + (isGrid && "productLines__selectBtn--grid")}>
            <NextButton
              disabled={loading || userDesignData === null}
              handleGoToFrameConfiguration={handleGoToFrameConfiguration}
            />
          </div>
        </div>
      </div>
      <ConfiguratorWelcomeDialog ref={configuratorWelcomeDialogRef} />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default ConfiguratorStartContent;
