import { FC, useEffect, useState, useLayoutEffect } from "react";
import { IDesign } from "../../interfaces/IDesign";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide, SwiperProps } from "swiper/react";
import { useStoreActions, useStoreState } from "../../hooks";
import { ScreenSize } from "../../utils/screen";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./ProductCarouselSwiper.css";
import { Typography } from "@mui/material";
import { DesignName } from "../../components/DesigneName/DesignName";

interface IProductCarouselProps {
  designs: IDesign[];
  setDesignName: (design: IDesign) => void;
  setSwiperInstance: (swp: SwiperProps) => void;
}

const FALLBACK_IMAGE = "./assets/images/image-not-found.png";

const ProductCarousel: FC<IProductCarouselProps> = (props) => {
  const { designs, setDesignName, setSwiperInstance } = props;
  const { userDesignData } = useStoreState((state) => state.designs.configuration);
  const [swiper, setSwiper] = useState<any>(null);
  const [currentIndex] = useState<number>(
    userDesignData?.design?.id !== undefined
      ? designs.findIndex((design) => design.id === userDesignData?.design?.id)
      : 0
  );
  const [selectedDesign, setSelectedDesign] = useState<IDesign>(designs[currentIndex]);

  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);
  const [numberOfSlides, setNumberOfSlides] = useState<number>(
    screenWidth > ScreenSize.LARGESCREEN
      ? 5
      : screenWidth <= ScreenSize.LARGESCREEN && screenWidth > ScreenSize.DESKTOP
        ? 4
        : screenWidth <= ScreenSize.DESKTOP && screenWidth > ScreenSize.TABLET
          ? 3
          : 1
  );

  const { setUserDesignDataFromDesign } = useStoreActions((actions) => actions.designs);

  const addDefaultImageOnError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    e.currentTarget.src = FALLBACK_IMAGE;
  };

  useEffect(() => {
    setDesignName(selectedDesign);
  }, []);

  useEffect(() => {
    setSwiperInstanceFunc();
  }, [swiper]);

  useEffect(() => {
    setNumberOfSlides(
      screenWidth > ScreenSize.LARGESCREEN
        ? 5
        : screenWidth <= ScreenSize.LARGESCREEN && screenWidth > ScreenSize.DESKTOP
          ? 4
          : screenWidth <= ScreenSize.DESKTOP && screenWidth > ScreenSize.TABLET
            ? 3
            : 1
    );
  }, [screenWidth]);

  useEffect(() => {
    if (swiper !== null) {
      swiper.slideTo(swiper.realIndex);
    }
  }, [numberOfSlides]);

  useLayoutEffect(() => {
    function updateSize() {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const handleSlideChange = (index: number) => {
    let selectedDesign = designs[index];
    setSelectedDesign(selectedDesign);
    setDesignName(selectedDesign);
    setUserDesignDataFromDesign(selectedDesign);
  };

  const setSwiperInstanceFunc = () => {
    setSwiperInstance(swiper);
  };

  return (
    <>
      {selectedDesign && (
        <Swiper
          onSlideChange={(swiper) => handleSlideChange(swiper.realIndex)}
          preloadImages={true}
          onSwiper={setSwiper}
          initialSlide={currentIndex}
          slidesPerView={numberOfSlides}
          spaceBetween={5}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
          loop={designs.length > numberOfSlides}
          centeredSlides={true}
          allowTouchMove={false}
          effect="slide"
          coverflowEffect={{ slideShadows: false }}
          slideToClickedSlide={true}
        >
          {designs.map((design, index) => (
            <SwiperSlide key={design.code}>
              {({ isActive }) => (
                <div className={`productCarousel__container`}>
                  <img
                    onError={addDefaultImageOnError}
                    src={design.designRangeUrl}
                    alt={design.code}
                  />
                  {!isActive && (
                    <Typography
                      variant="body1"
                      fontSize="16px"
                      fontWeight={300}
                    >
                      <DesignName design={design} />
                    </Typography>
                  )}
                </div>
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </>
  );
};

export default ProductCarousel;
