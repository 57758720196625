import { useStoreState } from "../../hooks";
import { useTranslation } from "react-i18next";
import { FC, useEffect, useState } from "react";
import { IDesign } from "../../interfaces/IDesign";
import { FUTURE_LINEAR } from "../../constants/DesignTypes";

export const DesignName: FC<{ design: IDesign }> = ({ design }) => {
  const countriesForImpressivo = ["DK", "FI", "SE", "NO"];
  const selectedCountryCode = useStoreState(
    (state) => state.countries?.countries.find((country) => country.id === state.auth.currentUser?.countryId)?.code
  );

  const { t } = useTranslation("specialNaming");

  const [name, setName] = useState<string>("");

  useEffect(() => {
    if (
      design?.name &&
      selectedCountryCode &&
      design.name === FUTURE_LINEAR &&
      countriesForImpressivo.includes(selectedCountryCode)
    ) {
      setName(t(`design_code.${selectedCountryCode}_futurelinear`));
    } else {
      setName(design?.name || "");
    }
  }, [selectedCountryCode, design]);

  return <>{name}</>;
};
