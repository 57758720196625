import { Grid } from "@mui/material";
import { useRef } from "react";
import { EFontPixelSize } from "../../../../../enum/EFontSize";
import { EIconPercentSize, EIconPixelSize } from "../../../../../enum/EIconSize";
import { ITileButtonProps } from "../../../../../interfaces/ITileButtonProps";
import { ITileDimension } from "../../../../../interfaces/ITileDimension";
import GridLine from "../../../Components/GridLine/GridLine";
import StandardTile from "../StandardTile";
import TileConfiguration from "../TileConfiguration";
import TilePreviewConfiguration from "../TilePreviewConfiguration";
import { LimitChar, isHorizontal } from "../limitChar";
import { FrameStyleProps } from "../../../../../interfaces/IFrameAndCoverStyle";
import { ELEMENTS } from "../../../../../constants/ItemsDimensions";

const TwoButtonsOneRocker: React.FC<ITileButtonProps> = (props) => {
  const border = props.isPreview ? "tile-border-preview" : "tile-border";

  const tileControlDimension: ITileDimension = {
    iconPixelDefault: EIconPixelSize.DEFAULT_CONTROL,
    iconSizeDefault: EIconPercentSize.MEDIUM,
    iconMinPercentage: EIconPercentSize.SMALL,
    iconMaxPercentage: EIconPercentSize.XLARGE,
    fontSizeDefault: EFontPixelSize.SMALL,
    fontSizeMin: EFontPixelSize.SMALL,
    fontSizeMax: EFontPixelSize.SMALL,
    textareaMaxHeight: 42,
    spaceBetweenIconAndTextArea: 4,
    isFunction: false,
  };

  const tileFunctionDimension: ITileDimension = {
    iconPixelDefault: EIconPixelSize.DEFAULT_FUNCTION,
    iconSizeDefault: EIconPercentSize.MEDIUM,
    iconMinPercentage: EIconPercentSize.STANDARD,
    iconMaxPercentage: EIconPercentSize.XLARGE,
    fontSizeDefault: EFontPixelSize.SMALL,
    fontSizeMin: EFontPixelSize.SMALL,
    fontSizeMax: EFontPixelSize.SMALL,
    textareaMaxHeight: 42,
    isFunction: true,
    spaceBetweenIconAndTextArea: 4,
  };

  const tileRockerDimension: ITileDimension = {
    iconPixelDefault: EIconPixelSize.DEFAULT_FUNCTION,
    iconSizeDefault: EIconPercentSize.MEDIUM,
    iconMinPercentage: EIconPercentSize.SMALL,
    iconMaxPercentage: EIconPercentSize.XLARGE,
    fontSizeDefault: EFontPixelSize.SMALL,
    fontSizeMin: EFontPixelSize.SMALL,
    fontSizeMax: EFontPixelSize.SMALL,
    textareaMaxHeight: 42,
    isFunction: false,
    spaceBetweenIconAndTextArea: 4,
  };

  const textFieldProps = LimitChar.get(props.designCode)!;
  const coverRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <div
        ref={coverRef}
        data-designcode={props.designCode}
      >
        <StandardTile designCode={props.designCode}>
          <Grid
            className={`${border} tile-border-rocker two-buttons-one-rocker-margin-bottom two-buttons-one-rocker-margin-right-btn printable-area-two-buttons-one-rocker-control`}
            container
            item
            alignItems="center"
            justifyContent="center"
            md={6}
          >
            {props.isPreview ? (
              <span>
                <TilePreviewConfiguration
                  tileDimension={tileControlDimension}
                  designCode={props.designCode}
                  textLabelSizes={
                    isHorizontal(props.insertDetail.rotate!)
                      ? textFieldProps.horizontal.oneButton
                      : textFieldProps.vertical.oneButton
                  }
                  tileName="twoButtonsOneRocker"
                  isRocker={false}
                  cover={props.cover}
                  insertDetail={props.insertDetail!}
                  indexDetail={ELEMENTS.ONE}
                />
              </span>
            ) : (
              <span>
                <TileConfiguration
                  isRocker={false}
                  textLabelSizes={
                    isHorizontal(props.insertDetail.rotate!)
                      ? textFieldProps.horizontal.twoButtonsOneRocker
                      : textFieldProps.vertical.twoButtonsOneRocker
                  }
                  tileName="twoButtonsOneRocker"
                  isHorizontal={props.isHorizontal}
                  designCode={props.designCode}
                  tileDimension={tileControlDimension}
                  insertDetail={props.insertDetail}
                  setInsertDetail={props.setInsertDetail}
                  cover={props.cover}
                  indexDetail={ELEMENTS.ONE}
                  coverRef={coverRef}
                  canManageRef={props.canManageRef}
                />
              </span>
            )}
          </Grid>
          <GridLine
            coverRef={coverRef}
            styleProps={{
              width: "1px",
              height: `calc(50% - ${FrameStyleProps.get(props.designCode)?.cover.padding})`,
              backgroundColor: props.insertDetail.insertColor?.secondaryPrintColor?.hexColor,
              position: "absolute",
              left: "calc(50% - 0.5px)",
            }}
            enable={props.insertDetail.hasGridLine}
            indexDetail={ELEMENTS.SIX}
            cover={props.cover}
            insertDetail={props.insertDetail}
            setInsertDetail={props.setInsertDetail}
            verticalDegrees={[0, 180]}
            defaultOrientation="vertical"
          />
          <Grid
            className={`${border} tile-border-rocker two-buttons-one-rocker-margin-left-btn two-buttons-one-rocker-margin-bottom printable-area-two-buttons-one-rocker-control`}
            container
            item
            alignItems="center"
            justifyContent="center"
            md={6}
          >
            {props.isPreview ? (
              <span>
                <TilePreviewConfiguration
                  tileDimension={tileControlDimension}
                  designCode={props.designCode}
                  textLabelSizes={
                    isHorizontal(props.insertDetail.rotate!)
                      ? textFieldProps.horizontal.oneButton
                      : textFieldProps.vertical.oneButton
                  }
                  tileName="twoButtonsOneRocker"
                  isRocker={false}
                  cover={props.cover}
                  insertDetail={props.insertDetail!}
                  indexDetail={ELEMENTS.TWO}
                />
              </span>
            ) : (
              <span>
                <TileConfiguration
                  isRocker={false}
                  textLabelSizes={
                    isHorizontal(props.insertDetail.rotate!)
                      ? textFieldProps.horizontal.twoButtonsOneRocker
                      : textFieldProps.vertical.twoButtonsOneRocker
                  }
                  tileName="twoButtonsOneRocker"
                  isHorizontal={props.isHorizontal}
                  designCode={props.designCode}
                  tileDimension={tileControlDimension}
                  insertDetail={props.insertDetail}
                  setInsertDetail={props.setInsertDetail}
                  cover={props.cover}
                  indexDetail={ELEMENTS.TWO}
                  coverRef={coverRef}
                  canManageRef={props.canManageRef}
                />
              </span>
            )}
          </Grid>
          <GridLine
            coverRef={coverRef}
            styleProps={{
              height: "1px",
              width: `calc(100% - ${FrameStyleProps.get(props.designCode)?.cover.padding} * 2)`,
              backgroundColor: props.insertDetail.insertColor?.secondaryPrintColor?.hexColor,
              position: "absolute",
              top: "calc(50% - 0.5px)",
            }}
            enable={props.insertDetail.hasGridLine}
            indexDetail={ELEMENTS.SEVEN}
            cover={props.cover}
            insertDetail={props.insertDetail}
            setInsertDetail={props.setInsertDetail}
            verticalDegrees={[90, 270]}
            defaultOrientation="horizontal"
          />
          <Grid
            className={`${border} tile-border-rocker-three two-buttons-one-rocker-margin-top two-buttons-one-rocker-margin-left printable-area-two-buttons-one-rocker-function`}
            container
            item
            alignItems=" center"
            justifyContent="center"
            md={4}
          >
            {props.isPreview ? (
              <span>
                <TilePreviewConfiguration
                  isRocker={true}
                  tileDimension={tileRockerDimension}
                  tileName="twoButtonsOneRocker"
                  designCode={props.designCode}
                  textLabelSizes={
                    isHorizontal(props.insertDetail.rotate!)
                      ? textFieldProps.horizontal.oneButton
                      : textFieldProps.vertical.oneButton
                  }
                  cover={props.cover}
                  insertDetail={props.insertDetail!}
                  indexDetail={ELEMENTS.THREE}
                />
              </span>
            ) : (
              <span>
                <TileConfiguration
                  isRocker={true}
                  tileDimension={tileRockerDimension}
                  tileName="twoButtonsOneRocker"
                  textLabelSizes={
                    isHorizontal(props.insertDetail.rotate!)
                      ? textFieldProps.horizontal.twoButtonsOneRocker.rocker!
                      : textFieldProps.vertical.twoButtonsOneRocker.rocker!
                  }
                  isHorizontal={props.isHorizontal}
                  designCode={props.designCode}
                  insertDetail={props.insertDetail}
                  setInsertDetail={props.setInsertDetail}
                  cover={props.cover}
                  indexDetail={ELEMENTS.THREE}
                  coverRef={coverRef}
                  canManageRef={props.canManageRef}
                />
              </span>
            )}
          </Grid>
          <Grid
            className={`${border} tile-border-rocker-three two-buttons-one-rocker-margin-left two-buttons-one-rocker-margin-top two-buttons-one-rocker-margin-bottom printable-area-two-buttons-one-rocker-function`}
            container
            item
            alignItems="center"
            justifyContent="center"
            md={4}
          >
            {props.isPreview ? (
              <span>
                <TilePreviewConfiguration
                  tileDimension={tileFunctionDimension}
                  designCode={props.designCode}
                  textLabelSizes={
                    isHorizontal(props.insertDetail.rotate!)
                      ? textFieldProps.horizontal.oneButton
                      : textFieldProps.vertical.oneButton
                  }
                  tileName="twoButtonsOneRocker"
                  isRocker={true}
                  cover={props.cover}
                  insertDetail={props.insertDetail!}
                  indexDetail={ELEMENTS.FOUR}
                />
              </span>
            ) : (
              <span>
                <TileConfiguration
                  isRocker={true}
                  textLabelSizes={
                    isHorizontal(props.insertDetail.rotate!)
                      ? textFieldProps.horizontal.twoButtonsOneRocker.rocker!
                      : textFieldProps.vertical.twoButtonsOneRocker.rocker!
                  }
                  tileName="twoButtonsOneRocker"
                  isHorizontal={props.isHorizontal}
                  designCode={props.designCode}
                  tileDimension={tileFunctionDimension}
                  insertDetail={props.insertDetail}
                  setInsertDetail={props.setInsertDetail}
                  cover={props.cover}
                  indexDetail={ELEMENTS.FOUR}
                  coverRef={coverRef}
                  canManageRef={props.canManageRef}
                />
              </span>
            )}
          </Grid>
          <Grid
            className={`${border} tile-border-rocker-three two-buttons-one-rocker-margin-left two-buttons-one-rocker-margin-bottom printable-area-two-buttons-one-rocker-function two-buttons-one-rocker-margin-left`}
            container
            item
            alignItems="center"
            justifyContent="center"
            md={4}
          >
            {props.isPreview ? (
              <span>
                <TilePreviewConfiguration
                  tileDimension={tileRockerDimension}
                  designCode={props.designCode}
                  textLabelSizes={
                    isHorizontal(props.insertDetail.rotate!)
                      ? textFieldProps.horizontal.oneButton
                      : textFieldProps.vertical.oneButton
                  }
                  tileName="twoButtonsOneRocker"
                  isRocker={true}
                  cover={props.cover}
                  insertDetail={props.insertDetail!}
                  indexDetail={ELEMENTS.FIVE}
                />
              </span>
            ) : (
              <span>
                <TileConfiguration
                  isRocker={true}
                  textLabelSizes={
                    isHorizontal(props.insertDetail.rotate!)
                      ? textFieldProps.horizontal.twoButtonsOneRocker.rocker!
                      : textFieldProps.vertical.twoButtonsOneRocker.rocker!
                  }
                  tileName="twoButtonsOneRocker"
                  isHorizontal={props.isHorizontal}
                  designCode={props.designCode}
                  tileDimension={tileRockerDimension}
                  insertDetail={props.insertDetail}
                  setInsertDetail={props.setInsertDetail}
                  cover={props.cover}
                  indexDetail={ELEMENTS.FIVE}
                  coverRef={coverRef}
                  canManageRef={props.canManageRef}
                />
              </span>
            )}
          </Grid>
        </StandardTile>
      </div>
    </>
  );
};

export default TwoButtonsOneRocker;
