import { useStoreActions, useStoreState } from "../hooks";
import { useEffect, useState } from "react";
import { IFoundFavoriteDesigns } from "../interfaces/IFoundFavoriteDesigns";

const useCheckFavoriteDesigns = () => {
  const { currentProjectId } = useStoreState((state) => state.designs.configuration);
  const [isLoading, setIsLoading] = useState(false);

  const { thunkCheckFavoriteDesigns, thunkGetProductDesignDataByProjectId } = useStoreActions(
    (actions) => actions.designs
  );

  const [checkedFavoriteDesigns, setCheckedFavoriteDesigns] = useState<IFoundFavoriteDesigns[]>([]);

  useEffect(() => {
    thunkGetProductDesignDataByProjectId(currentProjectId!);
  }, []);

  const checkDesigns = async () => {
    setIsLoading(true);
    try {
      const response = await thunkCheckFavoriteDesigns();
      setCheckedFavoriteDesigns(response);
    } catch (error) {
      console.error("Error during thunk execution:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    checkedFavoriteDesigns,
    checkDesigns,
    isLoading,
    setIsLoading,
  };
};

export default useCheckFavoriteDesigns;
